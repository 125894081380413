@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-ExtraBold.eot");
  src: local("Gilroy ExtraBold"), local("Gilroy-ExtraBold"),
    url("../public/fonts/Gilroy-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Gilroy-ExtraBold.woff") format("woff"),
    url("../public/fonts/Gilroy-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-UltraLightItalic.eot");
  src: local("Gilroy UltraLight Italic"), local("Gilroy-UltraLightItalic"),
    url("../public/fonts/Gilroy-UltraLightItalic.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Gilroy-UltraLightItalic.woff") format("woff"),
    url("../public/fonts/Gilroy-UltraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-BoldItalic.eot");
  src: local("Gilroy Bold Italic"), local("Gilroy-BoldItalic"),
    url("../public/fonts/Gilroy-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Gilroy-BoldItalic.woff") format("woff"),
    url("../public/fonts/Gilroy-BoldItalic.ttf") format("truetype");
  font-weight: Bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-SemiBold.eot");
  src: local("Gilroy SemiBold"), local("Gilroy-SemiBold"),
    url("../public/fonts/Gilroy-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Gilroy-SemiBold.woff") format("woff"),
    url("../public/fonts/Gilroy-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-BlackItalic.eot");
  src: local("Gilroy Black Italic"), local("Gilroy-BlackItalic"),
    url("../public/fonts/Gilroy-BlackItalic.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Gilroy-BlackItalic.woff") format("woff"),
    url("../public/fonts/Gilroy-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-ThinItalic.eot");
  src: local("Gilroy Thin Italic"), local("Gilroy-ThinItalic"),
    url("../public/fonts/Gilroy-ThinItalic.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Gilroy-ThinItalic.woff") format("woff"),
    url("../public/fonts/Gilroy-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-ExtraBoldItalic.eot");
  src: local("Gilroy ExtraBold Italic"), local("Gilroy-ExtraBoldItalic"),
    url("../public/fonts/Gilroy-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Gilroy-ExtraBoldItalic.woff") format("woff"),
    url("../public/fonts/Gilroy-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Black.eot");
  src: local("Gilroy Black"), local("Gilroy-Black"),
    url("../public/fonts/Gilroy-Black.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Gilroy-Black.woff") format("woff"),
    url("../public/fonts/Gilroy-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-HeavyItalic.eot");
  src: local("Gilroy Heavy Italic"), local("Gilroy-HeavyItalic"),
    url("../public/fonts/Gilroy-HeavyItalic.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Gilroy-HeavyItalic.woff") format("woff"),
    url("../public/fonts/Gilroy-HeavyItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Regular"), local("Gilroy-Regular"),
    url("../public/fonts/Gilroy-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-SemiBoldItalic.eot");
  src: local("Gilroy SemiBold Italic"), local("Gilroy-SemiBoldItalic"),
    url("../public/fonts/Gilroy-SemiBoldItalic.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Gilroy-SemiBoldItalic.woff") format("woff"),
    url("../public/fonts/Gilroy-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Medium.eot");
  src: local("Gilroy Medium"), local("Gilroy-Medium"),
    url("../public/fonts/Gilroy-Medium.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Gilroy-Medium.woff") format("woff"),
    url("../public/fonts/Gilroy-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Bold.eot");
  src: local("Gilroy Bold"), local("Gilroy-Bold"),
    url("../public/fonts/Gilroy-Bold.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Gilroy-Bold.woff") format("woff"),
    url("../public/fonts/Gilroy-Bold.ttf") format("truetype");
  font-weight: Bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-RegularItalic.eot");
  src: local("Gilroy Regular Italic"), local("Gilroy-RegularItalic"),
    url("../public/fonts/Gilroy-RegularItalic.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Gilroy-RegularItalic.woff") format("woff"),
    url("../public/fonts/Gilroy-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Light.eot");
  src: local("Gilroy Light"), local("Gilroy-Light"),
    url("../public/fonts/Gilroy-Light.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Gilroy-Light.woff") format("woff"),
    url("../public/fonts/Gilroy-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-LightItalic.eot");
  src: local("Gilroy Light Italic"), local("Gilroy-LightItalic"),
    url("../public/fonts/Gilroy-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Gilroy-LightItalic.woff") format("woff"),
    url("../public/fonts/Gilroy-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Heavy.eot");
  src: local("Gilroy Heavy"), local("Gilroy-Heavy"),
    url("../public/fonts/Gilroy-Heavy.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Gilroy-Heavy.woff") format("woff"),
    url("../public/fonts/Gilroy-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-MediumItalic.eot");
  src: local("Gilroy Medium Italic"), local("Gilroy-MediumItalic"),
    url("../public/fonts/Gilroy-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Gilroy-MediumItalic.woff") format("woff"),
    url("../public/fonts/Gilroy-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Thin.eot");
  src: local("Gilroy Thin"), local("Gilroy-Thin"),
    url("../public/fonts/Gilroy-Thin.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Gilroy-Thin.woff") format("woff"),
    url("../public/fonts/Gilroy-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

:root {
  --white: #FFFFFF;
  --current: #0D2339;
  --black: #0D2339;
  --blackHover: #173859;
  --gray: #5F6A76;
  --primary: #FFA321;
  --lightGray: #8C8A8A;
  --graySecond: #B2AFAF;
  --whiteGray: #F2EFEF;
  --lightWhiteGray: #F9F9F9;
  --red: #F2735F;
  --redHover: #FF6D55;
  --green: #4BD035;
  --blue: #498EF4;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .transition {
    transition: all 0.3s ease-in-out;
  }
}

@layer base {

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 16px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 100vh;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--black);
}

body {
  margin: 0;
  color: var(--black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.multiple .swiper-slide {
  width: 350px !important;
}

.modal .swiper-slide {
  width: 78vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

iframe {
  filter: grayscale(1);
}

.table {
  border-spacing: 0 16px;
  border: none;
  border-collapse: separate;
  font-size: 10px;
  text-align: left;
  width: 100%;
  font-weight: 500;
}

.table .spaceUnder>td:first-child {
  border-radius: 5px 0 0 5px;
}

.table .spaceUnder>td:last-child {
  border-radius: 0 5px 5px 0;
}

.table tr,
.sub-table tr {
  box-shadow: 0px 0px 7px rgba(188, 182, 182, 0.25);
  border-radius: 5px;
}

.table thead tr {
  box-shadow: none;
}

.table tr:last-child td {
  border-bottom: 0;
}

.table th:last-child {
  border-right: 0;
}

.table td:last-child {
  border-right: 0;
}

thead th {
  padding: 0;
}

/* .table td,
.table th {
  word-break: break-word;
} */

.sub-table {
  margin: 0;
  width: 100%;
}

.sub-table tr {
  box-shadow: none;
}

.text-overflow {
  display: -webkit-box;
  overflow-y: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.react-datepicker__close-icon::after {
  background-color: var(--gray);
}

.react-datepicker__close-icon {
  right: 10px;
}

.datepicker .react-datepicker {
  border: none;
}

.react-datepicker__current-month,
.react-datepicker__day-name {
  color: var(--white);
}

.react-datepicker__navigation-icon::before {
  margin-top: 5px;
  color: var(--white);
  border-color: var(--white);
}

.datepicker .react-datepicker__header {
  border-bottom: none;
}

.react-datepicker__day.react-datepicker__day--in-selecting-range {
  background-color: rgba(255, 163, 33, 0.5);
}

.datepicker .react-datepicker__header,
.react-datepicker__day react-datepicker__day--016 react-datepicker__day--selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: var(--primary);
  transition: all 0.3s ease-in-out;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border: 1px solid var(--primary);
  background-color: transparent;
  color: var(--black);
  transition: all 0.3s ease-in-out;
}

.react-datepicker__day {
  border: 1px solid transparent;
}

.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 70px;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--black);
}

.swiper-pagination-bullet {
  background-color: var(--gray);
}
